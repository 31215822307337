<template scoped>
  <div>
    <el-form-item
      :rules="rules.host"
      label="Host"
      prop="email.smtp.host"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="provider.email.smtp.host" placeholder=""></el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.port"
      label="Port"
      prop="email.smtp.port"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="provider.email.smtp.port" placeholder=""></el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.username"
      label="Username"
      prop="email.smtp.username"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="provider.email.smtp.username"
        placeholder=""
      ></el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.password"
      label="Password"
      prop="email.smtp.password"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="provider.email.smtp.password"
        placeholder=""
      ></el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.ssl"
      label="SSL"
      prop="email.smtp.ssl"
      :style="{ marginTop: '10px' }"
    >
      <el-switch
        v-model="provider.email.smtp.ssl"
        active-color="#13ce66"
        inactive-color="#ff4949"
      >
      </el-switch>
    </el-form-item>
  </div>
</template>
      
<script scoped>
export default {
  props: {
    provider: Object,
  },
  methods: {
    validator_host: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the port should not be empty"));
      } else {
        callback();
      }
    },
    validator_port: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the port should not be empty"));
      } else {
        callback();
      }
    },
    validator_username: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the username should not be empty"));
      } else {
        callback();
      }
    },
    validator_password: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the password should not be empty"));
      } else {
        callback();
      }
    },
  },
  data: function () {
    return {
      rules: {
        host: [{ validator: this.validator_host, trigger: "blur" }],
        port: [{ validator: this.validator_port, trigger: "blur" }],
        username: [{ validator: this.validator_username, trigger: "blur" }],
        password: [{ validator: this.validator_password, trigger: "blur" }],
      },
    };
  },
};
</script>    